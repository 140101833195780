<template>
    <div class="accreditations" :class="`accreditations-${theme}`">
    <div class="container">
        <div class="row justify-content-center">
          <div class="col-11">
            <component :is="headingLevel" class="text-secondary mb-4" :class="{ 'text-center': textCentered }">
              {{ $lang("home.accreditation.title", true) }}
            </component>
            <div class="bg-white p-6 accreditation-box">
              <div class="row align-items-center justify-content-center">
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <a href="https://acreditacion.cervantes.es/" target="_blank">
                  <img src="/imgs/certifications/01-cervantes.png" alt="Logo Cervantes" class="img-fluid certification-logo" style="max-height: 80px;" />
                  </a>
                </div>
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <img src="/imgs/certifications/02-dele.png" alt="Logo DELE" class="img-fluid certification-logo" style="max-height: 80px;" />
                </div>
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <img src="/imgs/certifications/03-siele.png" alt="Logo Siele" class="img-fluid certification-logo" style="max-height: 80px;" />
                </div>
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <img src="/imgs/certifications/04-ccse.png" alt="Logo CCSE" class="img-fluid certification-logo" style="max-height: 80px;" />
                </div>
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <img src="/imgs/certifications/05-csn.png" alt="Logo CSN" class="img-fluid certification-logo" style="max-height: 80px;" />
                </div>
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <img src="/imgs/certifications/06-cambridge.png" alt="Logo Cambridge" class="img-fluid certification-logo" style="max-height: 80px;" />
                </div>
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <img src="/imgs/certifications/07-erasmus.png" alt="Logo Erasmus" class="img-fluid certification-logo" style="max-height: 80px;" />
                </div>
                <div class="col-6 col-lg text-center mb-4 mb-lg-0">
                  <img src="/imgs/certifications/08-trinity.png" alt="Logo Trinity" class="img-fluid certification-logo" style="max-height: 80px;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Accreditations',
  props: {
    theme: {
      type: String,
      default: "",
    },
    headingLevel: {
      type: String,
      default: "h3",
      validator: (value) => ["h3", "h4"].includes(value)
    },
    textCentered: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style scoped>
.accreditation-box {
  border-radius: 18px;
}

.certification-logo {
  transform: scale(1.2);
}

@media (max-width: 1400px) {
  .certification-logo {
    transform: none;
  }
}
</style>